import Glass0_1_0a from '../releases/GLASS-0.1.0a.zip'
import patchNotes_0_2_0a from '../releases/patch-notes/0.2.0a-patch-notes.txt'

export const releases = [
    // {
    //     releaseName : "Alpha 0.2.0",
    //     fileName : "GLASS-0.2.0a.zip",
    //     filePath : Glass0_1_0a,
    //     releaseNotes :
    //         <ul>
    //             <li>Added several new command line arguments aimed at automation</li>
    //             <li>Interpretation script:</li>
    //             <ul>
    //                 <li>Added while loops</li>
    //                 <li>Added scope for variables</li>
    //                 <li>Added several new functions for type casting, user input, etc.</li>
    //                 <li>Added support for arrays and several other data types</li>
    //                 <li>Added support for floating point numbers</li>
    //                 <li>Added the ability to define custom functions</li>
    //                 <li>Changed some existing interpretation script features</li>
    //             </ul>
    //             <li>Several bug fixes</li>
    //         </ul>,
    //     patchNotesPath: patchNotes_0_2_0a,
    //     patchNotesName : "0.2.0a-patch-notes.txt"
    // },
    { 
        releaseName : "Alpha 0.1.0",
        fileName : "GLASS-0.1.0a.zip",
        filePath : Glass0_1_0a,
        releaseNotes : 
        <ul>
            <li>First alpha release</li>
        </ul>
    }
]

